import "@fontsource/ubuntu/400.css"
import "@fontsource/ubuntu/700.css"

export const onRouteUpdate = ({ location }) => {
    if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test'){
      return null;
    }
  
    const pagePath = location ? location.pathname + location.search + location.hash : undefined;
    setTimeout(() => {
      if (typeof gtag === 'function') {
        gtag('event', 'page_view', { page_path: pagePath });
      }
    }, 100);
  };